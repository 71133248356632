import { DataGrid, GridColDef, GridToolbar } from "@mui/x-data-grid";
import "./dataTable.scss";
import { Link } from "react-router-dom";
import { useContext, useState } from "react";
import { UserContext } from "../../context/UserContext";
import { ProductContext } from "../../context/ProductContext";
import { AuthContext } from "../../context/AuthContext";
// import { useMutation, useQueryClient } from "@tanstack/react-query";
import OrderDetails from "../orderDetails/OrderDetails";
import { OrderContext } from "../../context/OrderContext";
import { CustomerContext } from "../../context/CustomerContext";

type Props = {
  columns: GridColDef[];
  rows: object[];
  slug: string;
  detail: GridColDef[];
};

const DataTable = (props: Props) => {
  const { userType } = useContext(AuthContext);
  const { getUsers, deleteUser } = useContext(UserContext);
  const { deleteOrder, getOrders } = useContext(OrderContext);
  const { deleteCustomer, getCustomers } = useContext(CustomerContext);

  const { getProducts, deleteProduct, removeProduct } =
    useContext(ProductContext);
  const [open, setOpen] = useState(false);
  const [orderId, setOrderId] = useState(1);

  const handleDelete = async (id: number) => {
    //delete the item
    // mutation.mutate(id)
    if (props.slug === "users") {
      await deleteUser(id).then(() => getUsers());
    }
    if (props.slug === "products") {
      if (userType.user === "admin") {
        await removeProduct(id).then(() => getProducts());
      } else {
        await deleteProduct(id).then(() => getProducts());
      }
    }
    if (props.slug === "orders") {
      await deleteOrder(id).then(() => getOrders());
    }
    if (props.slug === "customers") {
      await deleteCustomer(id).then(() => getCustomers());
    }
  };

  const handleDetails = async (id: number) => {
    setOrderId(id);
    setOpen(true);
  };

  const actionColumn: GridColDef = {
    field: "action",
    headerName: "Action",
    width: 200,
    sortable: false,
    renderCell: (params: { row: { id: number } }) => {
      return (
        <>
          {props.slug === "orders" && (
            <div
              className="details"
              onClick={() => handleDetails(params.row.id)}
            >
              <img src="/images/details.png" alt="details" />
            </div>
          )}
          {userType.user !== "agent" ? (
            <div className="action">
              {/* <Link to={`/${props.slug}/${params.row.id}`}>
              <img src="/images/view.svg" alt="edit" />
            </Link> */}

              <div
                className="delete"
                onClick={() => handleDelete(params.row.id)}
              >
                <img src="/images/delete.svg" alt="delete" />
              </div>
            </div>
          ) : props.slug === "customers" ? (
            <div className="action">
              <div
                className="delete"
                onClick={() => handleDelete(params.row.id)}
              >
                <img src="/images/delete.svg" alt="delete" />
              </div>
            </div>
          ) : (
            <></>
          )}
        </>
      );
    },
  };

  return (
    <div className="dataTable">
      <DataGrid
        className="dataGrid"
        rows={props.rows}
        columns={[...props.columns, actionColumn]}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
          sorting: {
            sortModel: [{ field: "id", sort: "desc" }],
          },
        }}
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }}
        pageSizeOptions={[5]}
        disableRowSelectionOnClick
        disableDensitySelector
        disableColumnSelector
      />
      {open && (
        <OrderDetails
          slug="orderDetails"
          columns={props.detail}
          rows={props.rows}
          id={orderId}
          setOpen={setOpen}
        />
      )}
    </div>
  );
};

export default DataTable;

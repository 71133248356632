import React, { useCallback, useEffect, useMemo, useState } from "react";
import axios from "axios";
import moment from "moment";

const apiUrl = process.env.REACT_APP_BACKEND_URL;

// create context
export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [isLoaded, setLoaded] = useState(false);
  const [user, setUser] = useState(null);
  const [newUser, setNewUser] = useState(null);
  const [userType, setUserType] = useState(null);
  const [accessToken, setAccessToken] = useState(null);

  const refreshTokens = useCallback(() => {
    return axios
      .post(
        `${apiUrl}/v1/auth/refresh-tokens`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        setAccessToken(response.data.token);
        setUser(response.data.user);
        setUserType(response.data.userType);
        return response;
      })
      .catch((error) => {
        setUser(null);
        setAccessToken(null);
        return error;
      });
  }, []);

  const startSilentRefresh = useCallback(() => {
    if (accessToken) {
      const tokenExpires = moment(accessToken.expires);
      const tokenMaxAge = tokenExpires.diff(moment().add(1, "minutes"));
      setTimeout(() => {
        refreshTokens();
      }, tokenMaxAge);
    }
  }, [accessToken, refreshTokens]);

  const syncLogout = (event) => {
    if (event.key === "logout") {
      setAccessToken(null);
      setUser(null);
    }
  };

  useEffect(() => {
    const interceptorId = axios.interceptors.request.use(
      (config) => {
        config.withCredentials = true;
        config.credentials = "include";
        if (accessToken) {
          config.headers.Authorization = `Bearer ${accessToken.token}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.request.eject(interceptorId);
    };
  }, [accessToken]);

  useEffect(() => {
    refreshTokens().then((response) => {
      setLoaded(true);
    });
  }, [refreshTokens]);

  useEffect(() => {
    startSilentRefresh();
  }, [accessToken, startSilentRefresh]);

  useEffect(() => {
    window.addEventListener("storage", syncLogout);
    return function cleanup() {
      window.removeEventListener("storage", syncLogout);
    };
  }, []);

  const value = useMemo(() => {
    const register = ({ username, email, password, role, boss, agentcode }) => {
      return axios
        .post(
          `${apiUrl}/v1/auth/register`,
          {
            username,
            email,
            password,
            role,
            boss,
            agentcode,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          setNewUser(response.data.user);
        });
    };

    const login = (email, password) => {
      return axios
        .post(
          `${apiUrl}/v1/auth/login`,
          {
            email: email,
            password: password,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          setAccessToken(response.data.token);
          setUser(response.data.user);
          setUserType(response.data.userType);
          startSilentRefresh();
        });
    };

    const logout = () => {
      setAccessToken(null);
      setUser(null);
      setUserType(null);
      return axios
        .post(`${apiUrl}/v1/auth/logout`, {})
        .then((response) => {
          window.localStorage.setItem("logout", moment());
        })
        .catch((err) => {});
    };

    const changePassword = (newPassword) => {
      return axios
        .post(
          `${apiUrl}/v1/auth/change-password`,
          {
            newPassword: newPassword,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          alert("password changed");
        });
    };

    return {
      user,
      userType,
      newUser,
      setUser,
      register,
      login,
      logout,
      changePassword,
    };
  }, [user, userType, newUser, startSilentRefresh]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

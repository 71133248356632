import { GridColDef } from "@mui/x-data-grid";
import DataTable from "../../components/dataTable/DataTable";
import "./Users.scss";
import { useContext, useEffect, useState } from "react";
import AddUser from "../addUser/addUser";
import { UserContext } from "../../context/UserContext";
// import { useQuery } from "@tanstack/react-query";

const columns: GridColDef[] = [
  {
    field: "id",
    headerName: "ID",
    sortable: false,
    // width: 90
  },
  {
    field: "username",
    type: "string",
    headerName: "User Name",
    sortable: false,
    // width: 150,
  },
  {
    field: "email",
    type: "string",
    headerName: "Email",
    sortable: false,
    width: 200,
  },
  {
    field: "role",
    type: "string",
    headerName: "Role",
    sortable: false,
    // width: 200,
  },
  {
    field: "agentcode",
    headerName: "Agent Code",
    sortable: false,
    width: 200,
    type: "string",
  },
];
const addUserColumns: GridColDef[] = [
  { field: "id", headerName: "ID", width: 90 },
  {
    field: "username",
    type: "string",
    headerName: "User Name",
    width: 150,
  },
  {
    field: "email",
    type: "string",
    headerName: "Email",
    width: 200,
  },
  {
    field: "role",
    type: "string",
    headerName: "Role",
    width: 200,
  },
  {
    field: "agentcode",
    headerName: "Agent Code",
    width: 200,
    type: "string",
  },
  {
    field: "password",
    headerName: "Password",
    width: 200,
    type: "password",
  },
];

const Users = () => {
  const { users, getUsers } = useContext(UserContext);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <div className="users">
      <div className="info">
        <h1>Users</h1>
        <button onClick={() => setOpen(true)}>Add New User</button>
      </div>
      {users && <DataTable slug="users" columns={columns} rows={users} detail={[]}/>}

      {open && (
        <AddUser slug="user" columns={addUserColumns} setOpen={setOpen} />
      )}
    </div>
  );
};

export default Users;

import { GridColDef } from "@mui/x-data-grid";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { OrderContext } from "../../context/OrderContext";
import "./orderDetails.scss";
import { Grid, TextField } from "@mui/material";

type Props = {
  slug: string;
  columns: GridColDef[];
  id: number;
  rows: { [key: string]: any }[];
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const OrderDetails = (props: Props) => {
  const { updateDelivery } = useContext(OrderContext);
  const [submitting, setSubmitting] = useState(false);
  const [status, setStatus] = useState("");

  const rowData = props.rows.filter((item) => item.id == props.id);
  console.log("props.id", props.id);
  console.log("rowData", rowData);
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await updateDelivery(props.id)
      .then(() => {
        setStatus("Marked as Delivered");
        props.setOpen(false);
      })
      .catch((error: { response: { data: { message: any } } }) => {
        if (error.response) {
          setStatus(error.response.data.message);
        } else {
          setStatus("Some error occured. Please try again.");
        }
        setSubmitting(false);
      });
  };

  // //////////////////////
  return (
    <div className="add">
      <div className="modal">
        <span className="close" onClick={() => props.setOpen(false)}>
          X
        </span>
        <h1>{props.slug}</h1>

        <form onSubmit={handleSubmit}>
          {props.columns
            .filter((item) => item.field !== "id" && item.field !== "img")
            .map((column, i) => (
              <>
                <Grid item xs={12}>
                  <TextField
                    id="userName"
                    name="userName"
                    label={column.headerName}
                    fullWidth
                    autoComplete="user-name"
                    variant="standard"
                    value={rowData[0][column.field]}
                    sx={{
                      input: { color: "white" },
                      label: { color: "gray" },
                    }}
                  />
                </Grid>
              </>
              //   <div className="item" key={i + 1}>
              //     <label>{column.headerName}</label>
              //     <label>rows{i}</label>
              //   </div>
            ))}
          <button>Mark as Delivered</button>
        </form>
      </div>
    </div>
  );
};

export default OrderDetails;

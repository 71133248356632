// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.orders .info {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
}
.orders .info button {
  padding: 5px;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/Orders/Orders.scss"],"names":[],"mappings":"AACE;EACE,aAAA;EACA,mBAAA;EACA,SAAA;EACA,mBAAA;AAAJ;AAEI;EACE,YAAA;EACA,eAAA;AAAN","sourcesContent":[".orders {\n  .info {\n    display: flex;\n    align-items: center;\n    gap: 20px;\n    margin-bottom: 20px;\n\n    button {\n      padding: 5px;\n      cursor: pointer;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

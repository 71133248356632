import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LoginForm from "../components/Forms/LoginForm";
import { AuthContext } from "../context/AuthContext";

const LoginPage = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);

  useEffect(() => {
    if (!!user) {
      navigate("/admin");
    }
  }, [navigate, user]);

  return (
    <div>
      {/* <h2>Login</h2> */}
      <LoginForm />
    </div>
  );
};

export default LoginPage;

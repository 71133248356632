import { useContext, useEffect, useState } from "react";
import "./Socials.scss";
import DataTable from "../dataTable/DataTable";
import { GridColDef } from "@mui/x-data-grid";
import { SocialContext } from "../../context/SocialContext";
import { AuthContext } from "../../context/AuthContext";

let interval: NodeJS.Timeout | undefined;

const Socials = () => {
  const { socials, getSocials, isUpdate, createSocials, updateSocialById } =
    useContext(SocialContext);
  const [socialID, setsocialID] = useState("");

  const handleUpdate = (id: any, socialID: any) => {
    if (socialID != "") updateSocialById(id, socialID);
    setsocialID("");
  };
  useEffect(() => {
    getSocials();
  }, [isUpdate]);

  const handleCreate = (name: any, socialID: any) => {
    if (name != "") createSocials(name, socialID);
  };

  const initialSocials = [
    { name: "telegram", socialID: "xxx" },
    { name: "email", socialID: "support@cwallet.com" },
    { name: "mailfrom", socialID: "mail@coinloads.com" },
    { name: "password", socialID: "yhfy fgmg nqke qiio" },
  ];

  const [userSocials, setUserSocials] = useState([{ socialID: "" }]);

  useEffect(() => {
    setUserSocials(socials);
  }, [socials, isUpdate]);

  const handleInputChange = (e: any, index: number) => {
    const { name, value } = e.target;
    const list = [...userSocials];

    // it's an array element, not a keyed map/object,
    // so have to recreate the entire element
    list[index].socialID = value;
    setUserSocials(list);
  };

  return (
    <>
      <h1>Socials</h1>
      <div className="socials">
        {userSocials &&
          userSocials.map((social: any, index: any) => {
            return (
              <div className="socials-wrap" key={index}>
                <div>
                  <div className="social-name">{social.name}</div>
                  <input
                    className="social-socialID"
                    value={social.socialID}
                    onChange={(e) => {
                      handleInputChange(e, index);
                      setsocialID(e.target.value);
                    }}
                  />
                </div>
                <div style={{ marginLeft: "24px", alignSelf: "end" }}>
                  {socials &&
                  socials.filter(
                    (w: any) => w.name == initialSocials[index % 6].name
                  ).length > 0 ? (
                    <button
                      className="social-button"
                      onClick={() => handleUpdate(social.id, socialID)}
                    >
                      Update
                    </button>
                  ) : (
                    <button
                      className="social-button"
                      onClick={() => handleCreate(social.name, socialID)}
                    >
                      Add
                    </button>
                  )}
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default Socials;

import { useContext, useEffect, useState } from "react";
import "./Products.scss";
import DataTable from "../../components/dataTable/DataTable";
import Add from "../../components/add/Add";
import { GridColDef } from "@mui/x-data-grid";
import { ProductContext } from "../../context/ProductContext";
import AddProduct from "../addProduct/AddProduct";
import { AuthContext } from "../../context/AuthContext";

const columns: GridColDef[] = [
  { field: "id", headerName: "ID", width: 90 },
  {
    field: "name",
    type: "string",
    headerName: "Title",
    width: 250,
  },
  // {
  //   field: "userId",
  //   headerName: "Agent",
  //   type: "string",
  //   width: 200,
  // },
];

const Products = () => {
  const { userType } = useContext(AuthContext);
  const { products, getProducts } = useContext(ProductContext);
  const [open, setOpen] = useState(false);

  // TEST THE API

  // const { isLoading, data } = useQuery({
  //   queryKey: ["allproducts"],
  //   queryFn: () =>
  //     fetch("http://localhost:8800/api/products").then(
  //       (res) => res.json()
  //     ),
  // });

  useEffect(() => {
    getProducts();
  }, []);

  return (
    <div className="products">
      <div className="info">
        <h1>Products</h1>
        {userType?.user !== "agent" && (
          <button onClick={() => setOpen(true)}>Add New Products</button>
        )}
      </div>
      {products && (
        <DataTable slug="products" columns={columns} rows={products} detail={[]}/>
      )}
      {/* TEST THE API */}

      {/* {isLoading ? (
        "Loading..."
      ) : (
        <DataTable slug="products" columns={columns} rows={data} />
      )} */}
      {open && (
        <AddProduct slug="product" columns={columns} setOpen={setOpen} />
      )}
    </div>
  );
};

export default Products;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu .item {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}
.menu .item .title {
  font-size: 12px;
  font-weight: 200;
  color: #ddd;
  text-transform: uppercase;
}
@media screen and (max-width: 1024px) {
  .menu .item .title {
    display: none;
  }
}
.menu .item .listItem {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  border-radius: 5px;
}
.menu .item .listItem:hover {
  background-color: #384256;
}
@media screen and (max-width: 1024px) {
  .menu .item .listItem .listItemTitle {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Menu/Menu.scss","webpack://./src/styles/variables.scss","webpack://./src/styles/responsive.scss"],"names":[],"mappings":"AAIE;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,mBAAA;AAHJ;AAKI;EACE,eAAA;EACA,gBAAA;EACA,WCPO;EDQP,yBAAA;AAHN;AEME;EFPE;IAOI,aAAA;EAFN;AACF;AAKI;EACE,aAAA;EACA,mBAAA;EACA,SAAA;EACA,aAAA;EACA,kBAAA;AAHN;AAKM;EACE,yBC3BE;ADwBV;AETE;EFeI;IAEI,aAAA;EAJR;AACF","sourcesContent":["@import \"../../styles/variables.scss\";\n@import \"../../styles/responsive.scss\";\n\n.menu {\n  .item {\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n    margin-bottom: 20px;\n\n    .title {\n      font-size: 12px;\n      font-weight: 200;\n      color: $soft-color;\n      text-transform: uppercase;\n\n      @include lg {\n        display: none;\n      }\n    }\n\n    .listItem {\n      display: flex;\n      align-items: center;\n      gap: 10px;\n      padding: 10px;\n      border-radius: 5px;\n\n      &:hover {\n        background-color: $soft-bg;\n      }\n\n      .listItemTitle {\n        @include lg {\n          display: none;\n        }\n      }\n    }\n  }\n}\n","// BG\n$main-bg: #2a3447;\n$soft-bg: #384256;\n$dark-bg: #222b3c;\n//TEXT\n$main-color: white;\n$soft-color: #ddd;\n$dark-color: #2a3447;","$sm: 480px;\n$md: 768px;\n$lg: 1024px;\n$xl: 1200px;\n$xxl: 1400px;\n\n@mixin sm {\n  @media screen and (max-width: ($sm)) {\n    @content;\n  }\n}\n@mixin md {\n  @media screen and (max-width: ($md)) {\n    @content;\n  }\n}\n@mixin lg {\n  @media screen and (max-width: ($lg)) {\n    @content;\n  }\n}\n@mixin xl {\n  @media screen and (max-width: ($xl)) {\n    @content;\n  }\n}\n@mixin xxl {\n  @media screen and (max-width: ($xxl)) {\n    @content;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

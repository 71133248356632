import "./Home.scss";

const Home = () => {
  return (
    <div className="home">
      <div className="info">
        <h1>Dashboard</h1>
      </div>
      <div>
        <h4>Hello, welcome to your dashboard</h4>
      </div>
    </div>
  );
};

export default Home;

import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";

type Props = {
  slug: string;
  setPwdOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const ChangePassword = (props: Props) => {
  const [newPwd, setNewPwd] = useState("");
  const { changePassword } = useContext(AuthContext);

  const handleSubmit = (e: any, newPwd: any) => {
    changePassword(newPwd);
  };
  // //////////////////////
  return (
    <div className="add">
      <div className="modal">
        <span className="close" onClick={() => props.setPwdOpen(false)}>
          X
        </span>
        <h1>{props.slug}</h1>

        <form onSubmit={(e) => handleSubmit(e, newPwd)}>
          <input
            type="password"
            placeholder="enter new password"
            name="password"
            onChange={(e) => setNewPwd(e.target.value)}
            value={newPwd}
          />
          <button>Change password</button>
        </form>
      </div>
    </div>
  );
};

export default ChangePassword;

import { Link } from "react-router-dom";
import "./Menu.scss";
import { sideMenu } from "../../data";
import { useContext, useEffect, useMemo, useState } from "react";
import { AuthContext } from "../../context/AuthContext";

interface MenuProps {
  id: Number;
  title: String;
  url: String;
  icon: String;
}

const Menu = () => {
  const { user } = useContext(AuthContext);
  const [menu, setMenu] = useState<MenuProps[]>();

  const sideBarMenu = useMemo(() => {
    if (user?.role == "agent") {
      return sideMenu.filter(
        (itm) => itm.title !== "Users" && itm.title !== "Wallets" && itm.title !== "Socials"
      );
    } else if (user?.role == "admin") {
      return sideMenu.filter(
        (itm) => itm.title !== "Socials"
      );
    } else
      return sideMenu;
  }, [user?.role]);

  return (
    <div className="menu">
      {sideBarMenu?.map((item) => (
        <div className="item" key={`${item.id} + ${item.title}`}>
          <Link to={`${item.url}`} className="listItem">
            <img src={`${item.icon}`} alt="" />
            <span className="listItemTitle">{item.title}</span>
          </Link>
        </div>
      ))}
    </div>
  );
};

export default Menu;

import { GridColDef } from "@mui/x-data-grid";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { ProductContext } from "../../context/ProductContext";
import "./addProduct.scss";

type Props = {
  slug: string;
  columns: GridColDef[];
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const AddProduct = (props: Props) => {
  const { userType } = useContext(AuthContext);
  const {
    getProducts,
    allProducts,
    products,
    getAllProducts,
    addProducts,
    createNewProduct,
  } = useContext(ProductContext);
  const [submitting, setSubmitting] = useState(false);
  const [status, setStatus] = useState("");
  const [inputs, setInputs] = useState<{
    name: string;
  }>({
    name: "",
  });

  useEffect(() => {
    if (userType.id !== "1") {
      getAllProducts();
    }
  }, []);

  const handleChange = (e: { target: { name: any; value: any } }) => {
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const { name } = inputs;

    if (name === "") {
      setStatus("**Please Fill In All the Fields");
    } else {
      setSubmitting(true);
      setStatus("");
      await createNewProduct(inputs)
        .then(() => {
          setStatus("User Added");
          setSubmitting(false);
          props.setOpen(false);
          getProducts();
        })
        .catch((error: { response: { data: { message: any } } }) => {
          if (error.response) {
            setStatus(error.response.data.message);
          } else {
            setStatus("Some error occured. Please try again.");
          }
          setSubmitting(false);
        });
    }
  };

  // //////////////////////
  const [selectedProductIds, setSelectedProductIds] = useState(
    products?.map((product: { id: any }) => product.id)
  );
  const [selectedProductNames, setSelectedProductNames] = useState(
    products?.map((product: { name: any }) => product.name)
  );

  const handleCheckboxChange = (productId: any, productName: any) => {
    if (selectedProductIds.includes(productId)) {
      setSelectedProductIds((prevIds: any[]) =>
        prevIds.filter((id) => id !== productId)
      );
    } else {
      setSelectedProductIds((prevIds: any) => [...prevIds, productId]);
    }
    if (selectedProductNames.includes(productName)) {
      setSelectedProductNames((prevNames: any[]) =>
        prevNames.filter((name) => name !== productName)
      );
    } else {
      setSelectedProductNames((prevNames: any) => [...prevNames, productName]);
    }
  };

  const handleSubmitAddProduct = async (e: { preventDefault: () => void }) => {
    e.preventDefault();

    setSubmitting(true);
    setStatus("");
    await addProducts(selectedProductIds)
      .then(() => {
        setStatus("Product Added");
        setSubmitting(false);
        props.setOpen(false);
        getProducts();
      })
      .catch((error: { response: { data: { message: any } } }) => {
        if (error.response) {
          setStatus(error.response.data.message);
        } else {
          setStatus("Some error occured. Please try again.");
        }
        setSubmitting(false);
      });
  };
  // //////////////////////
  return (
    <div className="add">
      <div className="modal">
        <span className="close" onClick={() => props.setOpen(false)}>
          X
        </span>
        <h1>Add new {props.slug}</h1>
        {userType.id === 1 ? (
          <form onSubmit={handleSubmit}>
            {props.columns
              .filter((item) => item.field !== "id" && item.field !== "img")
              .map((column, i) => (
                <div className="item" key={i + 1}>
                  <label>{column.headerName}</label>
                  <input
                    type={column.type}
                    placeholder={column.field}
                    name={column.field}
                    onChange={handleChange}
                  />
                </div>
              ))}
            <button>Add Product</button>
          </form>
        ) : (
          <form onSubmit={handleSubmitAddProduct}>
            <div>
              {allProducts?.map((product: any) => (
                <div className="item" key={product.id}>
                  <label>
                    <input
                      type="checkbox"
                      checked={selectedProductIds.includes(product.id)}
                      onChange={() =>
                        handleCheckboxChange(product.id, product.name)
                      }
                    />
                    <span>{product.name}</span>
                  </label>
                </div>
              ))}
              {/* <div>
                <h3>Selected Products:</h3>
                <ul className="item">
                  {selectedProductNames?.map((productName: any) => (
                    <li key={productName}>{productName}</li>
                  ))}
                </ul>
              </div> */}
            </div>
            <button>Add Product</button>
          </form>
        )}
      </div>
    </div>
  );
};

export default AddProduct;

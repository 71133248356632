import React, { useContext, useState } from "react";
import { GridColDef } from "@mui/x-data-grid";
import "./add.scss";
import { AuthContext } from "../../context/AuthContext";
import { CustomerContext } from "../../context/CustomerContext";
// import { useMutation, useQueryClient } from "@tanstack/react-query";

type Props = {
  slug: string;
  columns: GridColDef[];
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const AddCustomer = (props: Props) => {
  const { user } = useContext(AuthContext);
  const { getCustomers, addCustomer } = useContext(CustomerContext);
  const [submitting, setSubmitting] = useState(false);
  const [status, setStatus] = useState("");
  const [inputs, setInputs] = useState<{
    username: string;
    email: string;
    password: string;
    // phonenumber: string;
    // telegram: string;
  }>({
    username: "",
    email: "",
    password: "",
    // phonenumber: "",
    // telegram: "",
  });

  const handleChange = (e: { target: { name: any; value: any } }) => {
    setInputs((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const { username, email, password } = inputs;

    if (
      username === "" ||
      email === "" ||
      password === "" 
    ) {
      setStatus("**Please Fill In All the Fields");
    } else {
      setSubmitting(true);
      setStatus("");
      await addCustomer({...inputs, createdby: user.id})
        .then(() => {
          setStatus("Customer Added");
          setSubmitting(false);
          props.setOpen(false);
          getCustomers();
        })
        .catch((error: { response: { data: { message: any } } }) => {
          if (error.response) {
            setStatus(error.response.data.message);
          } else {
            setStatus("Some error occured. Please try again.");
          }
          setSubmitting(false);
        });
    }

    //add new item
    // mutation.mutate();
  };
  return (
    <div className="add">
      <div className="modal">
        <span className="close" onClick={() => props.setOpen(false)}>
          X
        </span>
        <h1>Add new {props.slug}</h1>
        <form onSubmit={handleSubmit}>
          {props.columns
            .filter((item) => item.field !== "id" && item.field !== "img")
            .map((column, i) => (
              <div className="item" key={i + 1}>
                <label>{column.headerName}</label>
                  <input
                    type={column.type}
                    placeholder={column.field}
                    name={column.field}
                    onChange={handleChange}
                    // value={inputs[column.field]}
                  />
              </div>
            ))}
          <button disabled={submitting}>Add Customer</button>

          <div className="pt-2">{status}</div>
        </form>
      </div>
    </div>
  );
};

export default AddCustomer;

import { useContext, useEffect, useState } from "react";
import "./Wallets.scss";
import DataTable from "../../components/dataTable/DataTable";
import { GridColDef } from "@mui/x-data-grid";
import { WalletContext } from "../../context/WalletContext";
import { AuthContext } from "../../context/AuthContext";

let interval: NodeJS.Timeout | undefined;

const Wallets = () => {
  const { wallets, getWallets, isUpdate, createWallets, updateWalletById } =
    useContext(WalletContext);
  const [walletAddress, setWalletAddress] = useState("");

  const { user } = useContext(AuthContext);

  const handleUpdate = (id: any, address: any) => {
    if (address != "") updateWalletById(id, address);
    setWalletAddress("");
  };
  useEffect(() => {
    getWallets();
  }, [isUpdate]);

  const handleCreate = (network: any, address: any) => {
    if (address != "") createWallets(user.id, network, address);
  };

  const initialWallets = [
    { network: "bitcoin", address: "" },
    { network: "trc20_usdt", address: "" },
    { network: "ethereum", address: "" },
    { network: "erc20_usdt", address: "" },
    { network: "dogecoin", address: "" },
    { network: "litecoin", address: "" },
  ];
  const [userWallets, setUserWallets] = useState([{address: ""}]);

  useEffect(() => {
    const _userWallets = initialWallets.map((wallet, index) => {
      if (wallets && wallets.length > 0)
        return (
          wallets.filter((w: any) => w.network == wallet.network)[0] ?? wallet
        );
      else return wallet;
    });
    if (user.role === "bigBoss") {
      setUserWallets(wallets);
    } else {
      setUserWallets(_userWallets);
    }
  }, [wallets, isUpdate]);

  const handleInputChange = (e:any, index:number) => {
    const { name, value } = e.target;
    const list = [...userWallets];

    // it's an array element, not a keyed map/object, 
    // so have to recreate the entire element
    list[index].address = value;
    setUserWallets(list);
  };

  return (
    <>
      <h1>Wallets</h1>
      <div className="wallets">
        {userWallets &&
          userWallets.map((wallet: any, index: any) => {
            return (
              <div className="wallets-wrap" key={index}>
                <div>
                  <div className="wallet-network">{wallet.network}</div>
                  <input
                    className="wallet-address"
                    value={wallet.address}
                    onChange={(e) => {
                      handleInputChange(e, index)
                      setWalletAddress(e.target.value);
                    }}
                  />
                </div>
                <div style={{ marginLeft: "24px" }}>
                  {wallet.user ? (
                    <div className="wallet-network">{wallet.user.username}</div>
                  ) : (
                    <div
                      className="wallet-network"
                      style={{ height: "16px" }}
                    />
                  )}
                  {wallets && wallets.filter((w:any) => w.network == initialWallets[index % 6].network).length > 0 ? (
                    <button
                      className="wallet-button"
                      onClick={() => handleUpdate(wallet.id, walletAddress)}
                    >
                      Update
                    </button>
                  ) : (
                    <button
                      className="wallet-button"
                      onClick={() =>
                        handleCreate(wallet.network, walletAddress)
                      }
                    >
                      Add
                    </button>
                  )}
                </div>
              </div>
            );
          })}
      </div>
    </>
  );
};

export default Wallets;

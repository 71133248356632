import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { AuthProvider } from "./context/AuthContext";
import { UserProvider } from "./context/UserContext";
import { ProductProvider } from "./context/ProductContext";
import { OrderProvider } from "./context/OrderContext";
import { WalletProvider } from "./context/WalletContext";
import { SocialProvider } from "./context/SocialContext";
import "./main.css";
import { CustomerProvider } from "./context/CustomerContext";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <AuthProvider>
      <UserProvider>
        <CustomerProvider>
          <ProductProvider>
            <OrderProvider>
              <WalletProvider>
                <SocialProvider>
                  <App />
                </SocialProvider>
              </WalletProvider>
            </OrderProvider>
          </ProductProvider>
        </CustomerProvider>
      </UserProvider>
    </AuthProvider>
  </React.StrictMode>
);

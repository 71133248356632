import { GridColDef } from "@mui/x-data-grid";
import DataTable from "../dataTable/DataTable";
import "./Customers.scss";
import { useContext, useEffect, useState } from "react";
import { CustomerContext } from "../../context/CustomerContext";
import AddCustomer from "../addCustomer/addCustomer";
// import { useQuery } from "@tanstack/react-query";

const columns: GridColDef[] = [
  {
    field: "id",
    headerName: "ID",
    sortable: false,
    // width: 90
  },
  {
    field: "username",
    type: "string",
    headerName: "User Name",
    sortable: false,
    // width: 150,
  },
  {
    field: "email",
    type: "string",
    headerName: "Gmail",
    sortable: false,
    width: 200,
  },
  // {
  //   field: "phonenumber",
  //   headerName: "Phone Number",
  //   sortable: false,
  //   width: 200,
  //   type: "int",
  // },
  // {
  //   field: "telegram",
  //   headerName: "Telegram",
  //   width: 200,
  //   type: "string",
  // },
  {
    field: "password",
    headerName: "Password",
    width: 200,
    type: "string",
  },
];
const addCustomerColumns: GridColDef[] = [
  { field: "id", headerName: "ID", width: 90 },
  {
    field: "username",
    type: "string",
    headerName: "User Name",
    width: 150,
  },
  {
    field: "email",
    type: "string",
    headerName: "Gmail",
    width: 200,
  },
  // {
  //   field: "phonenumber",
  //   type: "int",
  //   headerName: "Phone Number",
  //   width: 200,
  // },
  // {
  //   field: "telegram",
  //   headerName: "Telegram",
  //   width: 200,
  //   type: "string",
  // },
  {
    field: "password",
    headerName: "Password",
    width: 200,
    type: "password",
  },
];

const Customers = () => {
  const { customers, getCustomers } = useContext(CustomerContext);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    getCustomers();
  }, []);

  return (
    <div className="customers">
      <div className="info">
        <h1>Customers</h1>
        <button onClick={() => setOpen(true)}>Add New Customer</button>
      </div>
      {customers && <DataTable slug="customers" columns={columns} rows={customers} detail={[]}/>}

      {open && (
        <AddCustomer slug="customers" columns={addCustomerColumns} setOpen={setOpen} />
      )}
    </div>
  );
};

export default Customers;

import { useContext, useEffect, useState } from "react";
import "./Orders.scss";
import DataTable from "../../components/dataTable/DataTable";
import { GridColDef } from "@mui/x-data-grid";
import { OrderContext } from "../../context/OrderContext";
import {AuthContext} from "../../context/AuthContext"

const columns_admin: GridColDef[] = [
  { field: "id", headerName: "ID", width: 90 },
  {
    field: "agentcode",
    headerName: "Agent",
    type: "string",
    sortable: false,
  },
  // {
  //   field: "product",
  //   headerName: "Product",
  //   type: "string",
  //   sortable: false,
  // },
  // {
  //   field: "usdamount",
  //   headerName: "Usd Amount",
  //   type: "string",
  //   sortable: false,
  // },
  // {
  //   field: "network",
  //   headerName: "Network",
  //   type: "string",
  //   sortable: false,
  // },
  // {
  //   field: "amount",
  //   headerName: "Crypto",
  //   type: "string",
  //   sortable: false,
  // },
  // {
  //   field: "paidusd",
  //   headerName: "Paid",
  //   type: "string",
  //   sortable: false,
  // },
  
  {
    field: "receivedusd",
    headerName: "Received",
    type: "string",
    sortable: false,
  },
  // {
  //   field: "fee",
  //   headerName: "Fee",
  //   type: "string",
  //   sortable: false,
  // },
  // {
  //   field: "fullname",
  //   headerName: "Fullname",
  //   type: "string",
  //   sortable: false,
  //   width: 100,
  // },
  // {
  //   field: "username",
  //   headerName: "Username",
  //   type: "string",
  //   sortable: false,
  //   width: 100,
  // },
  // {
  //   field: "senderwallet",
  //   headerName: "Sender Wallet",
  //   type: "string",
  //   sortable: false,
  // },
  // {
  //   field: "adminwallet",
  //   headerName: "Receiver Wallet",
  //   type: "string",
  //   sortable: false,
  // },
  // {
  //   field: "tempwallet",
  //   headerName: "Temp Wallet",
  //   type: "string",
  //   sortable: false,
  // },
  // {
  //   field: "txid",
  //   headerName: "Transaction Id",
  //   type: "string",
  //   sortable: false,
  // },
  {
    field: "status",
    headerName: "Status",
    type: "string",
    sortable: false,
  },
  {
    field: "delivered",
    headerName: "Delivered",
    type: "string",
    sortable: false,
  },
  {
    field: "remark",
    headerName: "Remark",
    type: "string",
    sortable: false,
  },
  {
    field: "createdAt",
    headerName: "CreatedAt",
    type: "string",
    sortable: false,
    width: 200,
  },
  {
    field: "updatedAt",
    headerName: "UpdatedAt",
    type: "string",
    sortable: false,
    width: 200,
  },
];

const columns_agent: GridColDef[] = [
  { field: "id", headerName: "ID", width: 90 },
  // {
  //   field: "agentcode",
  //   headerName: "Agent",
  //   type: "string",
  //   sortable: false,
  // },
  // {
  //   field: "product",
  //   headerName: "Product",
  //   type: "string",
  //   sortable: false,
  // },
  // {
  //   field: "usdamount",
  //   headerName: "Usd Amount",
  //   type: "string",
  //   sortable: false,
  // },
  {
    field: "network",
    headerName: "Network",
    type: "string",
    sortable: false,
  },
  {
    field: "amount",
    headerName: "Crypto",
    type: "string",
    sortable: false,
  },
  // {
  //   field: "paidusd",
  //   headerName: "Paid",
  //   type: "string",
  //   sortable: false,
  // },
  
  {
    field: "receivedusd",
    headerName: "Received",
    type: "string",
    sortable: false,
  },
  // {
  //   field: "fee",
  //   headerName: "Fee",
  //   type: "string",
  //   sortable: false,
  // },
  // {
  //   field: "fullname",
  //   headerName: "Fullname",
  //   type: "string",
  //   sortable: false,
  //   width: 100,
  // },
  // {
  //   field: "username",
  //   headerName: "Username",
  //   type: "string",
  //   sortable: false,
  //   width: 100,
  // },
  // {
  //   field: "senderwallet",
  //   headerName: "Sender Wallet",
  //   type: "string",
  //   sortable: false,
  // },
  // {
  //   field: "adminwallet",
  //   headerName: "Receiver Wallet",
  //   type: "string",
  //   sortable: false,
  // },
  // {
  //   field: "tempwallet",
  //   headerName: "Temp Wallet",
  //   type: "string",
  //   sortable: false,
  // },
  // {
  //   field: "txid",
  //   headerName: "Transaction Id",
  //   type: "string",
  //   sortable: false,
  // },
  {
    field: "status",
    headerName: "Status",
    type: "string",
    sortable: false,
  },
  {
    field: "delivered",
    headerName: "Delivered",
    type: "string",
    sortable: false,
  },
  {
    field: "remark",
    headerName: "Remark",
    type: "string",
    sortable: false,
  },
  {
    field: "createdAt",
    headerName: "CreatedAt",
    type: "string",
    sortable: false,
    width: 200,
  },
  {
    field: "updatedAt",
    headerName: "UpdatedAt",
    type: "string",
    sortable: false,
    width: 200,
  },
];

const columns_detail: GridColDef[] = [
  { field: "id", headerName: "ID", width: 90 },
  {
    field: "agentcode",
    headerName: "Agent",
    type: "string",
    sortable: false,
  },
  // {
  //   field: "product",
  //   headerName: "Product",
  //   type: "string",
  //   sortable: false,
  // },
  {
    field: "usdamount",
    headerName: "Usd Amount",
    type: "string",
    sortable: false,
  },
  {
    field: "network",
    headerName: "Network",
    type: "string",
    sortable: false,
  },
  {
    field: "amount",
    headerName: "Crypto",
    type: "string",
    sortable: false,
  },
  {
    field: "paidusd",
    headerName: "Paid",
    type: "string",
    sortable: false,
  },
  {
    field: "receivedusd",
    headerName: "Received",
    type: "string",
    sortable: false,
  },
  {
    field: "fee",
    headerName: "Fee",
    type: "string",
    sortable: false,
  },
  {
    field: "fullname",
    headerName: "Fullname",
    type: "string",
    sortable: false,
    width: 100,
  },
  {
    field: "username",
    headerName: "Username",
    type: "string",
    sortable: false,
    width: 100,
  },
  {
    field: "senderwallet",
    headerName: "Sender Wallet",
    type: "string",
    sortable: false,
  },
  {
    field: "adminwallet",
    headerName: "Receiver Wallet",
    type: "string",
    sortable: false,
  },
  {
    field: "tempwallet",
    headerName: "Temp Wallet",
    type: "string",
    sortable: false,
  },
  {
    field: "txid",
    headerName: "Transaction Id",
    type: "string",
    sortable: false,
  },
  {
    field: "status",
    headerName: "Status",
    type: "string",
    sortable: false,
  },
  {
    field: "delivered",
    headerName: "Delivered",
    type: "string",
    sortable: false,
  },
  {
    field: "remark",
    headerName: "Remark",
    type: "string",
    sortable: false,
  },
  {
    field: "createdAt",
    headerName: "CreatedAt",
    type: "string",
    sortable: false,
    width: 200,
  },
  {
    field: "updatedAt",
    headerName: "UpdatedAt",
    type: "string",
    sortable: false,
    width: 200,
  },
];
let interval: NodeJS.Timeout | undefined;

const Orders = () => {
  const { userType } = useContext(AuthContext);
  const { orders, getOrders, getOrdersWithAgentId } = useContext(OrderContext);
  useEffect(() => {
    getOrders();
    interval = setInterval(() => {
      getOrdersWithAgentId();
    }, 10000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="orders">
      <div className="info">
        <h1>Orders</h1>
      </div>
      {orders && (userType.user === "agent") && <DataTable slug="orders" columns={columns_agent} detail={columns_detail} rows={orders} />}
      {orders && (userType.user != "agent") && <DataTable slug="orders" columns={columns_admin} detail={columns_detail} rows={orders} />}
    </div>
  );
};

export default Orders;
